import React from "react";

const LoadingMedrais = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(255, 255, 255, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999, // Daha yüksek z-index
      }}
    >
      <img
        src={`https://medrais.com/medrais_images/MedraisNewLogo2.png`}
        alt="Blinking Icon"
        style={{
          width: "300px",
          animation: "blink 2s infinite",
        }}
      />
      <style>
        {`
          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.3; }
            100% { opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingMedrais;
